.Analytics{
    display: flex;
    //flex-direction: column;
    flex-flow: row wrap;
    .AnalyticsContainerBlock{
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        margin-top: 24px;
    }
    .AnalyticChart{
        min-width: 320px;
        background: var( --background );
        border-radius: 8px;
        padding: 12px;
        margin-left: 12px;
        margin-bottom: 24px;
    }
    .AnalyticChart:not(:first-child){
        margin-top: 24px;
    }
    .AnalyticsRow{
        display: flex;
    }
}