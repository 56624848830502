.MetricChart{
    display: flex;
    margin: 14px 0;
    .line{
        height: 8px;
    }
    .line:first-child{
        border-radius: 3px 0 0 3px;
    }
    .line:last-child{
        border-radius: 0 3px 3px 0;
    }
}