.Metrics{
    width: calc(300px - 48px);
    background: var( --background );
    padding: 24px;
    margin-left: 24px;
    border-radius: 8px;
    h1{
        font-size: 14px;
        font-weight: bold;
    }.MetricColorBlock{
        height: 8px;
        width: 8px;
    }
    .MetricsItems{
        display: flex;
        flex-flow: row wrap;
        .MetricItem{
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;
            span.itemName{
                font-size: 9px;
                word-break: break-all;
                white-space: wrap;
            }
            span.itemValue{
                font-size: 12px;
            }
            .itemPercent{
                font-size: 12px;
            }
            svg{
                width: 24px;
                height: 24px;
            }
        }
        .MetricItem:not(:last-child){
            margin-right: 6px;
        }
        .annotationText{
            display: flex;
            flex-direction: column;
            margin-bottom: 12px;
            .annotationTitle{
                font-weight: bold;
            }
        }
        .Item{
            width: auto;
            height: 8px;
            border-radius: 2px;
        }
        .itemTitle{
            font-size: 12px;
        }
        .NEGATIVE{
            svg{
                path{
                    fill: var( --echoRed);
                }
            }
        }
        .POSITIVE{
            svg{
                path{
                    fill: var( --echoGreen);
                }
            }
        }
        .NEUTRAL{
            svg{
                path{
                    fill: var( --echoSecondary);
                }
            }
        }
        .UNDEFINED{
            svg{
                path{
                    fill: var( --echoUndefined);
                }
            }
        }
    }

}