.SideBar{
    .LeftMenu, .RightMenu{
        width: 0;
        height: calc(100% - 200px);
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        background: var( --backgroundColor );
        transition: width 1s ease-in-out;
        overflow-y: auto;
    }
    /*.LeftBlock{
        margin-bottom: 24px;
    }*/
    .LeftMenu{
        left: 0;
        z-index: 2;
        //border-right: 1px solid var( --gray );
        .resizeBlock{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 12px;
            background: var( --background );
            border-radius: 8px;
            margin-top: 20px;
            margin-bottom: 20px;
            cursor: row-resize;
            .control{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                border: none;
                border-radius: 4px;
                background: var( --item );
                cursor: pointer;
                svg{
                    path{
                        stroke: var( --white );
                    }
                }
            }
            .control:disabled{
                background: var( --grey );
            }
            .showMoreIcon{
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 16px;
                svg{
                    fill: var( --item);
                    circle{
                        stroke: var(--item)
                    }
                }
            }
        }
    }
    .LeftMenu.showMenu{
        width: 300px;
        padding: 12px;
        z-index: 2;
    }
    .LeftMenu.showMobileMenu{
        width: 200px;
        transition: width 2s;
    }
    .RightMenu{
        right: 0;
        z-index: 2;
        //border-left: 1px solid var( --gray );
    }
    .twoBlocks{
        display: flex;
        overflow-y: visible;
       .LeftBlock{
            //height: calc(50% - 10px);
            overflow-y: auto;
            padding-right: 8px;
       }
       .LeftBlock:first-child{
        resize: vertical;
        //flex-grow: 1;
        border-bottom: 1px;
       }
       .LeftBlock:last-child{
        //flex-grow: 3;
       }
    }
    
    .RightMenu.showMenu{

        padding: 12px;
        width: 300px;
        .RightBlock{
            width: calc(100% - 24px);
            height: auto;
            min-height: 100%;
            background: var( --background );
            border-radius: 8px;
            padding: 12px;
            display: inline-table;
            .Header{
                width: 100%;
                display: flex;
                justify-content: space-between;
                span{
                    color: var( --caption );
                    font-size: 12px;
                }
                button{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: none;
                    background: none;
                }
            }
            
        }
    }
}

@media only screen and (max-device-width: 1200px), screen and (max-width: 1200px) {
    .SideBar{
        .RightMenu.showMenu{
            padding-top: 30px;
        }
    }
}