.KeywordsBlock{
    width: 98%;
    display: flex;
    margin-left: 12px;
    > div{
        width: 50%;
    }
    .AnalyticBlock{
        background: var( --background);
        border-radius: 8px;
        margin-right: 12px;
        height: 320px;
        margin-bottom: 24px;
    }
    
}